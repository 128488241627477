import React from "react";

const Whatsapp = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4867_1117)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3145 2.17987C12.9045 0.774902 11.0292 0.000823973 9.03137 0C4.91469 0 1.5643 3.33426 1.56265 7.43234C1.5621 8.74237 1.90596 10.0212 2.55957 11.1484L1.5 15L5.45928 13.9664C6.55023 14.5586 7.77843 14.8707 9.02833 14.8711H9.03146C13.1477 14.8711 16.4984 11.5366 16.5 7.43829C16.5008 5.45215 15.7247 3.58475 14.3145 2.17987ZM9.03137 13.6158H9.02879C7.91494 13.6154 6.82253 13.3174 5.8693 12.7547L5.64272 12.6207L3.29322 13.2341L3.92033 10.9544L3.77269 10.7206C3.15127 9.737 2.82313 8.6001 2.82369 7.4328C2.82497 4.02658 5.60978 1.25537 9.03385 1.25537C10.6919 1.25592 12.2506 1.89935 13.4226 3.06711C14.5946 4.23486 15.2396 5.78705 15.2391 7.43784C15.2376 10.8443 12.453 13.6158 9.03137 13.6158ZM12.4364 8.98883C12.2498 8.89581 11.3323 8.44666 11.1612 8.38458C10.9903 8.3226 10.8657 8.29175 10.7414 8.4776C10.617 8.66345 10.2594 9.08185 10.1505 9.20572C10.0415 9.32968 9.93281 9.34525 9.74615 9.25223C9.5595 9.1593 8.95824 8.9631 8.24538 8.33038C7.69066 7.83792 7.31616 7.22974 7.20724 7.04388C7.09851 6.85785 7.20632 6.76703 7.28912 6.66486C7.49113 6.41519 7.69342 6.15344 7.75561 6.02957C7.81789 5.90561 7.78671 5.79712 7.73997 5.70419C7.69342 5.61127 7.32021 4.69711 7.16474 4.32513C7.01314 3.96313 6.85942 4.01202 6.74479 4.00635C6.63606 4.00095 6.51159 3.99985 6.38713 3.99985C6.26275 3.99985 6.06055 4.04626 5.88944 4.2323C5.71843 4.41824 5.23639 4.86749 5.23639 5.78165C5.23639 6.6958 5.90508 7.57892 5.99836 7.70288C6.09164 7.82684 7.31432 9.70276 9.18629 10.5071C9.63153 10.6987 9.97908 10.8128 10.2502 10.8984C10.6973 11.0398 11.104 11.0198 11.4256 10.972C11.7842 10.9187 12.5296 10.5227 12.6852 10.0889C12.8407 9.65506 12.8407 9.28326 12.794 9.20572C12.7474 9.12827 12.623 9.08185 12.4364 8.98883Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4867_1117">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Whatsapp;
